
import { cityObject, currentYear } from "../constants/snp.constant";
import i18n from "../i18n";
import { IDistrictIncomeList, IFarmingAreaItem, IFarmingItem, IInvestmentProject, IPopulationDetails, IProductSufficiencyItem, ITaxStat } from "../interfaces/snp.interface";
import { getSettlementTree } from "../requests/kato.request";


const formatDate = (date: string) => {
  if (date) {
    return new Date(date).toLocaleDateString('ru').replaceAll('.', '-') || '';
  } else {
    return '';
  }
}

const t = (key: string, lng: string = 'ru', options?: any) => i18n.getResource(lng.toLowerCase(), 'translation', key, options) || key;

const getLang = () => {
  const { language } = i18n;
  return language === 'kz' ? 'Kz' : 'Ru';
}

const capitalize = (str: string) => str?.split(' ').map(str => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()).join(' ').replace(/  +/g, ' ');

const keepCapitalized = (str: string) => str?.split(' ').map(str => str.charAt(0) + str.slice(1).toLowerCase()).join(' ').replace(/  +/g, ' ');

function lowerAndTrim(inputString: string) {
  if (inputString) {
    const words = inputString.split(' ');

    const processWord = (word: string) => {
      let capitalCount = 0;
      let modifiedWord = '';

      for (let i = 0; i < word.length; i++) {
        if (word[i] === word[i].toUpperCase()) {
          capitalCount++;

          if (capitalCount <= 4) {
            modifiedWord += word[i];
          } else {
            modifiedWord += word[i].toLowerCase();
          }
        } else {
          modifiedWord += word[i].toLowerCase();
        }
      }

      return modifiedWord;
    };

    const processedWords = words.map(processWord);

    const result = processedWords.join(' ').replace(/  +/g, ' ');

    return result;
  }

  return '';
}

const formatNumber = (number: string) => number?.toString().split('').filter((item: string) => +item >= 1).join('') || '0'

const checkVal = (val: any) => val !== null && val !== undefined && val !== ''

const isRegion = (kato: number) => kato.toString()[4] === '0' && kato.toString()[5] === '0'

const getSelfSufficiencyPercentage = (item: IProductSufficiencyItem) => {
  const production = item.annualProductProduction ? item.annualProductProduction : 0;
  const consumption = item.annualConsumption ? item.annualConsumption : 0;
  return production === 0 || consumption === 0 ? 0 : ((production / consumption) * 100).toFixed(1);
};

const getDevelopmentPercentage = (items: IInvestmentProject[]) => {
  const current = items.reduce((acc, item) => acc + item.currentPrice, 0);
  const total = items.reduce((acc, item) => acc + item.totalPrice, 0);
  return total === 0 ? 0 : ((current / total) * 100).toFixed(1);
}

const calculateRoadSharePercentage = (val: number, total: number) => {
  const totalVal = total ? total : 0;
  const curr = val ? +val : 0;
  return totalVal === 0 ? 0 : ((curr / totalVal) * 100).toFixed(1);
};


const getObjectTotals = (items: any, key: string): string => {
  const list = items;
  if (list.length > 0) {
    if (key.toLowerCase().includes('percentage')) {
      return ((+getObjectTotals(list, key.replace('Percentage', 'Km')) / +getObjectTotals(list, 'totalKm')) * 100).toFixed(2);
    } else if (key.toLowerCase().includes('fine')) {
      if (key.toLowerCase().includes('prev')) {
        return (+getObjectTotals(list, 'prevYearGoodConditionKm') + +getObjectTotals(list, 'prevYearSatisfactoryConditionKm')).toFixed(2);
      } else {
        return (+getObjectTotals(list, 'goodConditionKm') + +getObjectTotals(list, 'satisfactoryConditionKm')).toFixed(2);
      }
    }
    else {
      return list.reduce((acc: number, item: any) => acc + +item[key], 0).toFixed(2);
    }
  }
  return '';
};

const getRegionTotals = (items: any, key: string): string => {
  const list = items;
  if (list.length > 0) {
    if (key.toLowerCase().includes('percentage')) {
      return ((+getRegionTotals(list, key.replace('Percentage', 'Km')) / +getRegionTotals(list, 'totalKm')) * 100).toFixed(2);
    } else {
      return list.reduce((acc: number, item: any) => acc + +getObjectTotals(item.roadsList, key), 0).toFixed(2);
    }
  }
  return ''
}


const getLastWeekdayDate = () => {
  const { language } = i18n;

  const months: Record<string, string[]> = {
    ru: ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'],
    kz: ['қаң', 'ақп', 'нау', 'сәу', 'мам', 'мау', 'шіл', 'там', 'қыр', 'қаз', 'қар', 'жел']
  }

  const today = new Date();
  let lastWeekday = today;

  if (today.getDay() === 0) {
    lastWeekday.setDate(today.getDate() - 2);
  } else if (today.getDay() === 6) {
    lastWeekday.setDate(today.getDate() - 1);
  }

  const day = lastWeekday.getDate().toString().padStart(2, '0');
  const month = months[language][lastWeekday.getMonth()];
  const year = lastWeekday.getFullYear();

  return `${language === 'ru' ? 'на' : ''} ${day} ${month} ${year} ${language === 'ru' ? 'г.' : 'ж.'}`;
};

const getIncomePercentage = (item: IDistrictIncomeList, key: string) => {
  const periodFact = item.periodFact || 0;
  const periodPlan = item.periodPlan || 0;
  const annualFact = item.annualFact || 0;
  const annualPlan = item.annualPlan || 0;

  if (key === 'periodImplementation') {
    return periodFact === 0 || periodPlan === 0 ? 0 : ((periodFact / periodPlan) * 100).toFixed(1);
  } else if (key === 'annualImplementation') {
    return annualFact === 0 || annualPlan === 0 ? 0 : ((annualFact / annualPlan) * 100).toFixed(1);
  }
}

const handleGoUp = (wrapperRef: any) => {
  if (wrapperRef && wrapperRef.current) {
    wrapperRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  }
}

const updateKatoAccessList = (katoAccessList: number[], kato: string, navigate: any, setTree: (tree: any) => void, setRegion: (region: any) => void, setSnp: (region: any) => void) => {
  if (katoAccessList?.length && kato) {
    if (!katoAccessList.includes(+kato) && !katoAccessList.includes(0) && !katoAccessList.some(item => kato.toString().startsWith(item.toString().substring(0, 4)))) {
      navigate('/admin/snp')
    }
    const snpItem: { kato: number, ppKato: number } = JSON.parse(localStorage.getItem('snp') as string);
    const val = kato ? kato : snpItem.kato;
    getSettlementTree().then((res: any) => {
      const data = res.data['Актобе'];

      const tree = Object.keys(data).map((item: string) => (
        {
          nameRu: data[item][0].pnameRu,
          nameKz: data[item][0].pnameKz,
          kato: data[item][0].pkato,
          children: katoAccessList.includes(0) ? data[item] : data[item].filter((item: any) => katoAccessList.includes(+item.pkato) || katoAccessList.includes(+item.kato))
        }))
      setTree(tree);
      // setTree([cityObject, ...tree]);

      if (val) {
        if (isRegion(+val)) {
          setRegion(+val);
        } else if (!isRegion(+val)) {
          setRegion(+(val.toString().substring(0, 4) + '00000'));
          setSnp(+val);
        }
      }
    });
  }
}

const getYearVal = (year: number, currentYear = 2025) => year === currentYear ? 'currentYearVal' : 'previousYearVal'

const findIndexByKey = (key: string, basicStats: any[]) => {
  const [type, subType] = key.split('-');

  const index = basicStats?.findIndex((item: any) => item.type === type && item.subType === subType);

  return index;
}

const getIncreaseVal = (stat: any, basicStats: any[]) => {
  const item = basicStats?.find((item: any) => item.type === stat.type && item.subType === stat.subType);

  const current = item.currentYearVal ? +item.currentYearVal : 0;
  const previous = item.previousYearVal ? +item.previousYearVal : 0;
  if (current === 0 || previous === 0) return 0;

  return ['SER', 'MCB', 'EMPLOYMENT', 'LIVESTOCK'].includes(item.type) ? (((current - previous) / previous) * 100).toFixed(1) : item.type === 'BUDGET' ? ((current / previous) * 100).toFixed(2) : (current - previous).toFixed(2);
}

const getPopulationDetails = (stats: IPopulationDetails, key: string) => {
  const femaleCount = stats.femaleCount ? +stats.femaleCount : 0;
  const maleCount = stats.maleCount ? +stats.maleCount : 0;

  const immigrationCount = stats.immigrationCount ? +stats.immigrationCount : 0;
  const emigrationCount = stats.emigrationCount ? +stats.emigrationCount : 0;

  const populationEmploymentCount = stats.populationEmploymentCount ? +stats.populationEmploymentCount : 0;

  const birthCount = stats.birthCount ? +stats.birthCount : 0;
  const deathCount = stats.deathCount ? +stats.deathCount : 0;

  if (key === 'populationCount') {
    return femaleCount + maleCount;
  } else if (key === 'saldoMigration') {
    return immigrationCount - emigrationCount;
  } else if (key === 'economicallyActivePopulation') {
    return femaleCount === 0 || maleCount === 0 || populationEmploymentCount === 0 ? 0 : ((populationEmploymentCount / (femaleCount + maleCount)) * 100).toFixed(1);
  } else if (key === 'naturalCount') {
    return birthCount - deathCount;
  }

  return 0;
}

const getTaxValue = (data: ITaxStat, key: keyof ITaxStat): number => {
  const taxRevenuesFact = data.taxRevenuesFact ? +data.taxRevenuesFact : 0;
  const nonTaxRevenuesFact = data.nonTaxRevenuesFact ? +data.nonTaxRevenuesFact : 0;
  const fixedAssetsSaleFact = data.fixedAssetsSaleFact ? +data.fixedAssetsSaleFact : 0;

  const taxRevenuesPlan = data.taxRevenuesPlan ? +data.taxRevenuesPlan : 0;
  const nonTaxRevenuesPlan = data.nonTaxRevenuesPlan ? +data.nonTaxRevenuesPlan : 0;
  const fixedAssetsSalePlan = data.fixedAssetsSalePlan ? +data.fixedAssetsSalePlan : 0;

  const taxRevenuesAnnual = data.taxRevenuesAnnual ? +data.taxRevenuesAnnual : 0;
  const nonTaxRevenuesAnnual = data.nonTaxRevenuesAnnual ? +data.nonTaxRevenuesAnnual : 0;
  const fixedAssetsSaleAnnual = data.fixedAssetsSaleAnnual ? +data.fixedAssetsSaleAnnual : 0;

  if (key === 'fact') {
    return +(taxRevenuesFact + nonTaxRevenuesFact + fixedAssetsSaleFact).toFixed(1);
  } else if (key === 'plan') {
    return +(taxRevenuesPlan + nonTaxRevenuesPlan + fixedAssetsSalePlan).toFixed(1);
  } else if (key === 'annual') {
    return +(taxRevenuesAnnual + nonTaxRevenuesAnnual + fixedAssetsSaleAnnual).toFixed(1);
  } else if (key === 'planPercent') {
    return +getTaxValue(data, 'fact') === 0 || +getTaxValue(data, 'plan') === 0 ? 0 : +((+getTaxValue(data, 'fact') / +getTaxValue(data, 'plan')) * 100).toFixed(1);
  } else if (key === 'annualPercent') {
    return +getTaxValue(data, 'fact') === 0 || +getTaxValue(data, 'annual') === 0 ? 0 : +((+getTaxValue(data, 'fact') / +getTaxValue(data, 'annual')) * 100).toFixed(1);
  } else if (key === 'taxRevenuesPlanPercent') {
    return taxRevenuesFact === 0 || taxRevenuesPlan === 0 ? 0 : +((taxRevenuesFact / taxRevenuesPlan) * 100).toFixed(1);
  } else if (key === 'nonTaxRevenuesPlanPercent') {
    return nonTaxRevenuesFact === 0 || nonTaxRevenuesPlan === 0 ? 0 : +((nonTaxRevenuesFact / nonTaxRevenuesPlan) * 100).toFixed(1);
  } else if (key === 'fixedAssetsSalePlanPercent') {
    return fixedAssetsSaleFact === 0 || fixedAssetsSalePlan === 0 ? 0 : +((fixedAssetsSaleFact / fixedAssetsSalePlan) * 100).toFixed(1);
  } else if (key === 'taxRevenuesAnnualPercent') {
    return taxRevenuesFact === 0 || taxRevenuesAnnual === 0 ? 0 : +((taxRevenuesFact / taxRevenuesAnnual) * 100).toFixed(1);
  } else if (key === 'nonTaxRevenuesAnnualPercent') {
    return nonTaxRevenuesFact === 0 || nonTaxRevenuesAnnual === 0 ? 0 : +((nonTaxRevenuesFact / nonTaxRevenuesAnnual) * 100).toFixed(1);
  } else if (key === 'fixedAssetsSaleAnnualPercent') {
    return fixedAssetsSaleFact === 0 || fixedAssetsSaleAnnual === 0 ? 0 : +((fixedAssetsSaleFact / fixedAssetsSaleAnnual) * 100).toFixed(1);
  }

  return 0;
}

const formatNumberForScreening = (value: string) => {
  const val = value.replaceAll(',', '.')
  return Number.isInteger(+val) ? +val : (+val).toFixed(1);
}

const getKHLPHTotal = (item: IFarmingItem) => {
  const kh = item.kh ? +item.kh : 0;
  const lph = item.lph ? +item.lph : 0;
  return formatNumberForScreening((kh + lph).toString());
}

const getCropTotal = (item: IFarmingAreaItem) => {
  const vegetables = item.vegetables ? +item.vegetables : 0;
  const grains = item.grains ? +item.grains : 0;
  const potato = item.potato ? +item.potato : 0;
  const carnival = item.carnival ? +item.carnival : 0;
  const forage = item.forage ? +item.forage : 0;

  return formatNumberForScreening((vegetables + grains + potato + carnival + forage).toString());
}

const splitChartLabels = (labels: string[]) => {
  return labels.map((item) => {
    const words = item.split(' ');
    return words.reduce((result: any, word: string) => {
      if (word.length === 1 && result.length > 0) {
        result[result.length - 1] += ` ${word}`;
      } else {
        result.push(word);
      }
      return result;
    }, []);
  });
}

export {
  formatDate,
  t,
  getLang,
  capitalize,
  lowerAndTrim,
  keepCapitalized,
  formatNumber,
  checkVal,
  isRegion,
  getSelfSufficiencyPercentage,
  getDevelopmentPercentage,
  calculateRoadSharePercentage,
  getObjectTotals,
  getRegionTotals,
  getLastWeekdayDate,
  getIncomePercentage,
  handleGoUp,
  updateKatoAccessList,
  getYearVal,
  findIndexByKey,
  getIncreaseVal,
  getPopulationDetails,
  getTaxValue,
  getKHLPHTotal,
  getCropTotal,
  formatNumberForScreening,
  splitChartLabels
}