import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Footer, FooterButton, FormBlock, FormGroup, Wrapper } from '../../components/admin-page/admin-page.styles';
import { Form, Formik, Field } from 'formik';
import { GoUp, Save } from '../../assets/icons';
import { checkVal, getLang, handleGoUp, isRegion, t, updateKatoAccessList } from '../../utils/helpers.utils';
import { toast, ToastContainer } from 'react-toastify';
import { EmploymentCategories, IHumanResources, SocialCategories } from '../../interfaces/snp.interface';
import { updateScreening } from '../../requests/screening.request';
import { currentYear, OBLAST_KATO } from '../../constants/snp.constant';
import { useSharedContext } from '../../contexts/shared.context';

interface IProps {
  data: IHumanResources;
  updateForm: () => void;
}

const HumanResourcesPage: FC<IProps> = ({ data, updateForm }) => {
  const { i18n: { language } } = useTranslation();
  const navigate = useNavigate();
  const { kato } = useParams();
  const { selectedDataYear } = useSharedContext();

  const formikRef = useRef<any>(null);
  const wrapperRef = useRef<any>(null);

  const [tree, setTree] = useState<any[]>([]);
  const [region, setRegion] = useState<number>(0);
  const [snp, setSnp] = useState<number>(0);
  const [katoAccessList, setKatoAccessList] = useState<number[]>([]);
  const [isKatoRegion, setIsKatoRegion] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [humanResourcesData, setHumanResourcesData] = useState<IHumanResources>();

  const validateForm = (data: any) => {
    if (!humanResourcesData) return;
    setErrors({});
    let res = true;

    const populationDynamicData = humanResourcesData.populationDynamic;

    if (populationDynamicData && populationDynamicData.some((item) => checkVal(item.population)) && populationDynamicData.some((item) => !checkVal(item.population))) {
      for (const item of populationDynamicData) {
        if (!checkVal(item.population)) {
          setErrors((prev: any) => ({ ...prev, [`populationDynamic.${item.year}`]: true }))
          toast.error(t(`errors.populationDynamic.${item.year}`, language));
          return false;
        }
      }
    }

    const populationByAgeData = humanResourcesData.populationByAge;
    if (populationByAgeData && populationByAgeData.some((item) => checkVal(item.population)) && populationByAgeData.some((item) => !checkVal(item.population))) {
      for (const item of populationByAgeData) {
        if (!checkVal(item.population)) {
          setErrors((prev: any) => ({ ...prev, [`populationByAge.${item.age}`]: true }))
          toast.error(t(`errors.populationByAge.${item.age}`, language));
          return false;
        }
      }
    }

    const populationBySocialStatusData = humanResourcesData.populationBySocialStatus;
    if (populationBySocialStatusData && Object.values(populationBySocialStatusData).some((item) => checkVal(item)) && Object.values(populationBySocialStatusData).some((item) => !checkVal(item))) {
      for (const key in populationBySocialStatusData) {
        if (!checkVal(populationBySocialStatusData[key as SocialCategories])) {
          setErrors((prev: any) => ({ ...prev, [`populationBySocialStatus.${key}`]: true }))
          toast.error(t(`errors.populationBySocialStatus.${key}`, language));
          return false;
        }
      }
    }

    const employmentData = humanResourcesData.employment;
    if (employmentData && Object.values(employmentData).some((item) => checkVal(item)) && Object.values(employmentData).some((item) => !checkVal(item))) {
      for (const key in employmentData) {
        if (!checkVal(employmentData[key as EmploymentCategories])) {
          setErrors((prev: any) => ({ ...prev, [`employment.${key}`]: true }))
          toast.error(t(`errors.employment.${key}`, language));
          return false;
        }
      }
    }

    return res;
  }

  const handleSubmitForm = (values: any) => {
    setErrors({});

    if (kato && validateForm(humanResourcesData)) {
      updateScreening(+kato, 'humanResources', { ...humanResourcesData }, selectedDataYear)
        .then(res => {
          updateForm();
          toast.success(t('toast.save_success'))
        })
    }
  }

  const handleRegionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setRegion(+e.target.value);
    kato && isRegion(+kato) && navigate(`/admin/${e.target.value}/screening/human-resources`)
  }

  const handleSnpChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSnp(+e.target.value)
    navigate(`/admin/${e.target.value}/screening/human-resources`)
  }

  const renderSelects = (lang: 'Ru' | 'Kz' = 'Ru') => {
    return (
      kato && +kato !== OBLAST_KATO && <div className="grid-item">
        <FormGroup>
          <label htmlFor="region">{t(`form.region.name`, lang)}</label>
          <Field as="select" value={region} onChange={handleRegionChange} disabled={lang.toLowerCase() !== language}>
            {tree.map((item) => <option key={item.kato} value={item.kato}>{item[`name${getLang()}`]}</option>)}
          </Field>
        </FormGroup>

        {!isKatoRegion && <FormGroup>
          <label htmlFor="snp">{t(`form.district.name`, lang)}</label>
          <Field as="select" value={snp} onChange={handleSnpChange} disabled={lang.toLowerCase() !== language}>
            {tree.find(item => +item.kato === +region)?.children.map((item: any) => <option key={item.kato} value={item.kato}>{item[`name${getLang()}`]}</option>)}
          </Field>
        </FormGroup>}
      </div>
    )
  }

  const renderFields = (lang: 'Ru' | 'Kz', setFieldValue: any) => {
    return <div className="grid-item">
      {
        humanResourcesData && <>
          {
            <FormBlock type='white'>
              <div className="title bold">{t('screening-page.population-dynamic', lang)}</div>
              {
                humanResourcesData.populationDynamic && humanResourcesData.populationDynamic.map((item, index) => (
                  <FormGroup key={index}>
                    <label
                      htmlFor={`humanResourcesData.populationDynamic[${index}].population`}
                      className="required"
                    >{item.year}
                    </label>
                    <Field
                      disabled={currentYear !== selectedDataYear}
                      name={`humanResourcesData.populationDynamic[${index}].population`}
                      className={`${errors[`populationDynamic[${index}].population`] ? 'error' : ''}`}
                      type='number'
                      as="input"
                      min={0}
                      step={'0.0001'}
                      onChange={(e: any) => {
                        const val = e.target.value || '';
                        setHumanResourcesData({
                          ...humanResourcesData,
                          populationDynamic: humanResourcesData.populationDynamic && humanResourcesData.populationDynamic.map((item, i) => i === index ? {
                            year: item.year,
                            population: val
                          } : item)
                        });
                        setFieldValue(`humanResourcesData.populationDynamic[${index}].population`, val);
                        setFieldValue(`humanResourcesData.populationDynamic[${index}].year`, item.year);
                      }}
                      value={humanResourcesData?.populationDynamic?.[index].population || ''}
                    />
                  </FormGroup>
                ))
              }

            </FormBlock>
          }

          {
            humanResourcesData.populationByAge && <FormBlock type='white'>
              <div className="title bold">{t('screening-page.population-by-age', lang)}</div>
              {
                humanResourcesData.populationByAge && humanResourcesData.populationByAge.map((item, index) => (
                  <FormGroup key={index}>
                    <label
                      htmlFor={`humanResourcesData.populationByAge[${index}].population`}
                      className="required"
                    >{t(`screening-page.${item.age}`, lang)}
                    </label>
                    <Field
                      disabled={currentYear !== selectedDataYear}
                      name={`humanResourcesData.populationByAge[${index}].population`}
                      className={`${errors[`populationByAge[${index}].population`] ? 'error' : ''}`}
                      type='number'
                      as="input"
                      min={0}
                      step={'0.0001'}
                      onChange={(e: any) => {
                        const val = e.target.value || '';
                        setHumanResourcesData({
                          ...humanResourcesData,
                          populationByAge: humanResourcesData.populationByAge && humanResourcesData.populationByAge.map((item, i) => i === index ? {
                            age: item.age,
                            population: val
                          } : item)
                        });
                        setFieldValue(`humanResourcesData.populationByAge[${index}].population`, val);
                        setFieldValue(`humanResourcesData.populationByAge[${index}].age`, item.age);
                      }}
                      value={humanResourcesData?.populationByAge?.[index].population || ''}
                    />
                  </FormGroup>
                ))
              }
            </FormBlock>
          }

          {
            humanResourcesData.populationBySocialStatus && <FormBlock type='white'>
              <div className="title bold">{t('screening-page.population-by-social-status', lang)}</div>
              {
                humanResourcesData.populationBySocialStatus && <>
                  {
                    Object.values(SocialCategories).map((item, index) => (
                      <FormGroup key={index}>
                        <label
                          htmlFor={`humanResourcesData.populationBySocialStatus.${item}`}
                          className="required"
                        >{t(`screening-page.${item}`, lang)}
                        </label>
                        <Field
                          disabled={currentYear !== selectedDataYear}
                          name={`humanResourcesData.populationBySocialStatus.${item}`}
                          className={`${errors[`humanResourcesData.populationBySocialStatus.${item}`] ? 'error' : ''}`}
                          type='number'
                          as="input"
                          min={0}
                          step={'0.0001'}
                          onChange={(e: any) => {
                            const val = e.target.value || '';
                            humanResourcesData.populationBySocialStatus && setHumanResourcesData({
                              ...humanResourcesData,
                              populationBySocialStatus: {
                                ...humanResourcesData.populationBySocialStatus,
                                [item]: val,
                              }
                            });
                            setFieldValue(`humanResourcesData.populationBySocialStatus.${item}`, e.target.value);
                          }}
                          value={(humanResourcesData.populationBySocialStatus && humanResourcesData.populationBySocialStatus[item as SocialCategories]) || ''}
                        />
                      </FormGroup>
                    ))
                  }
                  {
                    <FormGroup >
                      <label
                        htmlFor={`humanResourcesData.populationBySocialStatus.disabled`}
                        className="required"
                      >{t(`screening-page.disabled`, lang)}
                      </label>
                      <Field
                        name={`humanResourcesData.populationBySocialStatus.disabled`}
                        className={`${errors[`humanResourcesData.populationBySocialStatus.disabled`] ? 'error' : ''}`}
                        type='number'
                        as="input"
                        min={0}
                        step={'0.0001'}
                        disabled
                        value={
                          ((+humanResourcesData.populationBySocialStatus[SocialCategories.firstGroupDisabled] || 0)
                            + (+humanResourcesData.populationBySocialStatus[SocialCategories.secondGroupDisabled] || 0)
                            + (+humanResourcesData.populationBySocialStatus[SocialCategories.thirdGroupDisabled] || 0))
                        }
                      />
                    </FormGroup>
                  }
                </>
              }
            </FormBlock>
          }

          {
            humanResourcesData.employment && <FormBlock type='white'>
              <div className="title bold">{t('screening-page.employment', lang)}</div>
              {
                humanResourcesData.employment && <>
                  {
                    Object.values(EmploymentCategories).map((item, index) => (
                      <FormGroup key={index}>
                        <label
                          htmlFor={`humanResourcesData.employment.${item}`}
                          className="required"
                        >{t(`screening-page.${item}`, lang)}
                        </label>
                        <Field
                          name={`humanResourcesData.employment.${item}`}
                          disabled={currentYear !== selectedDataYear}
                          className={`${errors[`humanResourcesData.employment.${item}`] ? 'error' : ''}`}
                          type='number'
                          as="input"
                          min={0}
                          step={'0.0001'}
                          onChange={(e: any) => {
                            const val = e.target.value || '';
                            humanResourcesData.employment && setHumanResourcesData({
                              ...humanResourcesData,
                              employment: {
                                ...humanResourcesData.employment,
                                [item]: val,
                              }
                            });
                            setFieldValue(`humanResourcesData.employment.${item}`, e.target.value);
                          }}
                          value={(humanResourcesData.employment && humanResourcesData.employment[item as EmploymentCategories]) || ''}
                        />
                      </FormGroup>
                    ))
                  }
                </>
              }
            </FormBlock>
          }
        </>
      }
    </div>
  }


  useEffect(() => {
    kato && updateKatoAccessList(katoAccessList, kato, navigate, setTree, setRegion, setSnp);
  }, [katoAccessList, kato]);

  useEffect(() => {
    if (kato) {
      setIsKatoRegion(isRegion(+kato))
    }
  }, [kato])

  useEffect(() => {
    const item = localStorage.getItem('user');
    if (item) {
      const snpInfo = JSON.parse(item);
      if (snpInfo && snpInfo.kato_access) {
        setKatoAccessList(snpInfo.kato_access)
      }
    }
  }, [])

  useEffect(() => {
    if (data) {
      setHumanResourcesData(data);
    }
  }, [data])

  return (
    <div style={{ position: 'relative' }}>
      {
        <>
          <Wrapper ref={wrapperRef}>
            <Formik
              initialValues={{}}
              onSubmit={(values) => {
                handleSubmitForm(values);;
              }}
              innerRef={formikRef}
            >
              {({ setFieldValue }) => (
                <Form>
                  {renderSelects(getLang())}
                  {renderSelects(getLang() !== 'Kz' ? 'Kz' : 'Ru')}
                  {renderFields(getLang(), setFieldValue)}
                  {renderFields(getLang() !== 'Kz' ? 'Kz' : 'Ru', setFieldValue)}
                  <Footer>
                    <div className="buttons">
                      <FooterButton disabled={currentYear !== selectedDataYear} variant="save"><Save /> {t('save', language)}</FooterButton>
                    </div>
                    <div className="buttons">
                      <FooterButton variant="go-up" type='button' onClick={() => handleGoUp(wrapperRef)}><GoUp /> {t('go-up', language)}</FooterButton>
                    </div>
                  </Footer>
                </Form>
              )}
            </Formik>
          </Wrapper>
        </>
      }
      <ToastContainer />
    </div>
  )
}

export default HumanResourcesPage