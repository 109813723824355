import React, { FC, useEffect, useState } from 'react'
import { BasicStatsItem } from '../index.styles'
import { Text } from '../../text.component'
import { Bar } from 'react-chartjs-2';
import { IMCB, IScreeningMCBCreditSupport, IScreeningRegisteredSubjects } from '../../../interfaces/snp.interface';
import { mcbSubjectsOptions, registeredSubjectsOptions } from './chart-options';
import { creditSupportKeys } from '../../../pages/admin/mcb.page';
import { useTranslation } from 'react-i18next';
import { getLang } from '../../../utils/helpers.utils';
import i18n from '../../../i18n';

const registeredSubjectsKeysRu: Record<keyof IScreeningRegisteredSubjects, string | string[]> = {
  juridical: 'Юридические',
  agricultural: 'Сельхоз',
  construction: 'Строительные',
  retail: ['Розничная', 'торговля'],
  printing: ['Услуги', 'печати'],
  finance: ['Финансовая', 'среда'],
  commerce: ['Коммерческие', 'услуги'],
}

const registeredSubjectsKeysKz: Record<keyof IScreeningRegisteredSubjects, string | string[]> = {
  juridical: 'Заңды',
  agricultural: ['Ауыл', 'шаруашылығы'],
  construction: 'Құрылыс',
  retail: ['Бөлшек', 'сауда'],
  printing: ['Басып шығару', 'қызметтері'],
  finance: ['Қаржылық', 'орта'],
  commerce: ['Коммерциялық', 'қызметтер'],
}

const subjectsKeysRu = {
  active: ['Количество', 'действующих', 'субъектов МСБ'],
  registered: ['Количество', 'зарегистрированных', 'субъектов МСБ'],
}

const subjectsKeysKz = {
  active: ['Әрекеттегі ШОБ', 'субъектілерінің', 'саны'],
  registered: ['Тіркелген ШОБ', 'субъектілерінің', 'саны'],
}

interface IProps {
  data: IMCB
}

const MCBTab: FC<IProps> = ({ data }) => {
  const { t } = useTranslation();
  const { language } = i18n;

  const [mcbSubjects, setMcbSubjects] = useState<any>(null);
  const [registeredSubjects, setRegisteredSubjects] = useState<any>(null);
  const [creditSupport, setCreditSupport] = useState<IScreeningMCBCreditSupport | null>(null);

  const MCB_header = [
    t('screening-page.credit-support.category'),
    t('screening-page.credit-support.subsidizing_projects'),
    t('screening-page.credit-support.subsidizing_sum'),
  ]

  const buildGraphs = () => {
    if (data) {

      const subjectLabels = language === 'ru' ? subjectsKeysRu : subjectsKeysKz;

      setMcbSubjects({
        labels: Object.values(subjectLabels),
        datasets: [
          {
            label: t('screening-page.mcb-subjects.total-short'),
            data: [(data.mcbSubjects?.total ? +data.mcbSubjects?.total : 0), null,],
            backgroundColor: '#118F84',
            borderRadius: 5,
            barThickness: 40,
          },
          {
            label: t('screening-page.mcb-subjects.TOO'),
            data: [null, (data.mcbSubjects?.TOO ? +data.mcbSubjects?.TOO : 0)],
            backgroundColor: '#92D050',
            borderRadius: 5,
            barThickness: 40,
          },
          {
            label: t('screening-page.mcb-subjects.IP'),
            data: [null, (data.mcbSubjects?.IP ? +data.mcbSubjects?.IP : 0)],
            backgroundColor: '#5BC7D9',
            borderRadius: 5,
            barThickness: 40,
          },
          {
            label: t('screening-page.mcb-subjects.SPK'),
            data: [null, (data.mcbSubjects?.SPK ? +data.mcbSubjects?.SPK : 0)],
            backgroundColor: '#dedede',
            borderRadius: 5,
            barThickness: 40,
          },
          {
            label: t('screening-page.mcb-subjects.KH'),
            data: [null, (data.mcbSubjects?.KH ? +data.mcbSubjects?.KH : 0)],
            backgroundColor: '#2E6CBD',
            borderRadius: 5,
            barThickness: 40,
          },
        ],
      })

      const initialRegisteredSubjectsKeys = data.registeredSubjects && Object.keys(data.registeredSubjects);
      const registeredSubjectKeys = initialRegisteredSubjectsKeys?.map((key: string) => {
        const keysObj = language === 'ru' ? registeredSubjectsKeysRu : registeredSubjectsKeysKz
        if (key in keysObj) {
          return keysObj[key]
        } else {
          const label = data.registeredSubjects?.[key][`label${getLang()}`]

          return label.split(' ').length > 1 ? label.split(' ') : label
        }
      })

      const registeredSubjectData = initialRegisteredSubjectsKeys?.map((key: string) => {
        const keysObj = language === 'ru' ? registeredSubjectsKeysRu : registeredSubjectsKeysKz;
        if (key in keysObj) {
          return data.registeredSubjects?.[key] || 0
        } else {
          return data.registeredSubjects?.[key].value || 0
        }
      })

      setRegisteredSubjects({
        labels: registeredSubjectKeys,
        datasets: [
          {
            label: 'Субъекты МСБ',
            data: registeredSubjectData,
            borderColor: '#009187',
            backgroundColor: '#009187',
            pointBackgroundColor: '#009187',
            borderRadius: 5,
            barThickness: 42,
          }
        ]
      })

      setCreditSupport({
        grant_projects: data.creditSupport?.grant_projects || '0',
        grant_sum: data.creditSupport?.grant_sum || '0',
        subsidizing_projects: data.creditSupport?.subsidizing_projects || '0',
        subsidizing_sum: data.creditSupport?.subsidizing_sum || '0',
        guarantee_projects: data.creditSupport?.guarantee_projects || '0',
        guarantee_sum: data.creditSupport?.guarantee_sum || '0'
      })
    }
  }

  useEffect(() => {
    buildGraphs()
  }, [data, language])

  return (
    <div className='grid c-2 screening human-resources'>
      {
        data.mcbSubjects && mcbSubjects && <BasicStatsItem>
          <div className="title">
            <div className="left">
              <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{t('screening-page.mcb-subjects.title')}</Text>
            </div>
          </div>
          {data.mcbSubjects && mcbSubjects && <div className="body">
            <Bar data={mcbSubjects} options={mcbSubjectsOptions} />
          </div>}
        </BasicStatsItem>
      }

      {
        data.registeredSubjects && registeredSubjects && <BasicStatsItem>
          <div className="title">
            <div className="left">
              <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{t('screening-page.registered-subjects.title')}</Text>
            </div>
          </div>
          <div className="body">
            <Bar data={registeredSubjects} options={registeredSubjectsOptions} />
          </div>
        </BasicStatsItem>
      }

      {
        data.totalSum && <BasicStatsItem>
          <div className="title mcb-title">
            <div className="left">
              <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{t('screening-page.totalSum')}</Text>
            </div>
          </div>
          <div className="body mcb-body">
            <div className="value">
              <Text color='var(--primary-color)' fontWeight={700} fontSize='4.25rem'>{data.totalSum || 0}</Text>
              <Text color='#AEB0AF' fontWeight={500} fontSize='3rem'>млн тенге</Text>
            </div>
          </div>
        </BasicStatsItem>
      }

      {
        <BasicStatsItem>
          <div className="title">
            <div className="left">
              <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{t('screening-page.credit-support.title')}</Text>
            </div>
          </div>
          <div className="body ">
            <table className="mcb-table">
              <thead>
                <tr>
                  {
                    MCB_header.map((item) => (
                      <th key={item}>
                        <Text fontSize='0.75rem' color='#606367' fontWeight={400}>{item}</Text>
                      </th>
                    ))
                  }
                </tr>
              </thead>
              <tbody>
                {
                  creditSupport && creditSupportKeys.map((key) => (
                    <tr key={key}>
                      <td>
                        <Text fontSize='0.875rem' color='#202224' fontWeight={400}>{t('screening-page.credit-support.' + key)}</Text>
                      </td>
                      <td>
                        <Text fontSize='1.875rem' color='var(--primary-color)' fontWeight={700}>{creditSupport[`${key}_projects` as keyof IScreeningMCBCreditSupport]}</Text>
                      </td>
                      <td>
                        <Text fontSize='1.875rem' color='var(--primary-color)' fontWeight={700}>{creditSupport[`${key}_sum` as keyof IScreeningMCBCreditSupport]}</Text>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </BasicStatsItem>
      }
    </div>
  )
}

export default MCBTab