import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Footer, FooterButton, FormBlock, FormGroup, Wrapper } from '../../components/admin-page/admin-page.styles';
import { Form, Formik, Field } from 'formik';
import { AddField, GoUp, Save } from '../../assets/icons';
import { checkVal, getLang, handleGoUp, isRegion, t, updateKatoAccessList } from '../../utils/helpers.utils';
import { toast, ToastContainer } from 'react-toastify';
import { ILandFund, ILandFundItem, ILargeLandowners } from '../../interfaces/snp.interface';
import { FormBlockButton } from '../../components/insfrastructureProjects/infrastructure.styles';
import { updateScreening } from '../../requests/screening.request';
import { currentYear, OBLAST_KATO } from '../../constants/snp.constant';
import { useSharedContext } from '../../contexts/shared.context';

interface IProps {
  data: ILandFund;
  updateForm: () => void;
}

const keys: (keyof ILargeLandowners)[] = ['name', 'total_land', 'total_arable', 'total_pasture', 'fact_land', 'fact_arable', 'fact_pasture',];
const landFundItemKeys: (keyof ILandFundItem)[] = ['settlementLand', 'agricultural_farmEnterprises', 'agricultural_arable', "agricultural_pasture", 'agricultural_total', 'reserve_arable', 'reserve_pasture', 'reserve_total', 'otherLand'];

const defaultObj: ILargeLandowners = {
  name: '',
  fact_arable: 0,
  fact_pasture: 0,
  fact_land: 0,
  total_arable: 0,
  total_pasture: 0,
  total_land: 0,
}

const countTotal = (type: 'reserve' | 'agricultural', data: ILandFundItem) => {
  return +data[`${type}_arable`] + +data[`${type}_pasture`];
}

const LandFundPage: FC<IProps> = ({ data, updateForm }) => {
  const { i18n: { language } } = useTranslation();
  const navigate = useNavigate();
  const { kato } = useParams();
  const { selectedDataYear } = useSharedContext();

  const formikRef = useRef<any>(null);
  const wrapperRef = useRef<any>(null);

  const [tree, setTree] = useState<any[]>([]);
  const [region, setRegion] = useState<number>(0);
  const [snp, setSnp] = useState<number>(0);
  const [katoAccessList, setKatoAccessList] = useState<number[]>([]);
  const [isKatoRegion, setIsKatoRegion] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [landFundData, setLandFundData] = useState<ILandFund>();

  const [isAdding, setIsAdding] = useState(false);
  const [newObj, setNewObj] = useState<ILargeLandowners>(defaultObj);

  const validateForm = (data: any) => {
    if (!landFundData) return;
    setErrors({});
    let res = true;

    const largeLandowners: ILargeLandowners[] = landFundData.largeLandowners || [];

    if (largeLandowners
      && largeLandowners.some((item) => keys.some((key) => checkVal(item[key]))
        && keys.some((key) => !checkVal(item[key])))) {
      for (const item of largeLandowners) {
        if (!checkVal(item.name) || !checkVal(item.fact_arable)) {
          setErrors((prev: any) => ({ ...prev, [`largeLandowners.${item.name}`]: true }))
          toast.error(t('errors.largeLandowners', language));
          return;
        }
      }
    }

    const landFundItems = landFundData.landFundItems || {} as ILandFundItem;
    if (Object.values(landFundItems).some((item) => !checkVal(item)) && Object.values(landFundItems).some((item) => checkVal(item))) {
      for (const key in landFundItems) {
        if (!key.includes('total') && !checkVal(landFundItems[key as keyof ILandFundItem])) {
          setErrors((prev: any) => ({ ...prev, [`landFundItems.${key}`]: true }))
          toast.error(t('errors.landFundItems', language));
          return;
        }
      }
    }

    return res;
  }

  const handleSubmitForm = (values: any) => {
    setErrors({});

    if (kato && validateForm(landFundData)) {
      updateScreening(+kato, 'landFund', { ...landFundData }, selectedDataYear)
        .then(() => {
          updateForm();
          toast.success(t('toast.save_success'))
        })
    }
  }

  const handleRegionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setRegion(+e.target.value);
    kato && isRegion(+kato) && navigate(`/admin/${e.target.value}/screening/land-fund`)
  }

  const handleSnpChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSnp(+e.target.value)
    navigate(`/admin/${e.target.value}/screening/land-fund`)
  }

  const renderSelects = (lang: 'Ru' | 'Kz' = 'Ru') => {
    return (
      kato && +kato !== OBLAST_KATO && <div className="grid-item">
        <FormGroup>
          <label htmlFor="region">{t(`form.region.name`, lang)}</label>
          <Field as="select" value={region} onChange={handleRegionChange} disabled={lang.toLowerCase() !== language}>
            {tree.map((item) => <option key={item.kato} value={item.kato}>{item[`name${getLang()}`]}</option>)}
          </Field>
        </FormGroup>

        {!isKatoRegion && <FormGroup>
          <label htmlFor="snp">{t(`form.district.name`, lang)}</label>
          <Field as="select" value={snp} onChange={handleSnpChange} disabled={lang.toLowerCase() !== language}>
            {tree.find(item => +item.kato === +region)?.children.map((item: any) => <option key={item.kato} value={item.kato}>{item[`name${getLang()}`]}</option>)}
          </Field>
        </FormGroup>}
      </div>
    )
  }

  const handleAddObj = () => {
    if (Object.values(newObj).every((item) => checkVal(item))) {
      landFundData && setLandFundData({
        ...landFundData,
        largeLandowners: [...(landFundData.largeLandowners || []), newObj]
      });
      setNewObj(defaultObj);
      setIsAdding(false);
    }
  }

  useEffect(() => {
    if (data) {
      setLandFundData(data);
    }
  }, [data]);


  const renderFields = (lang: 'Ru' | 'Kz', setFieldValue: any) => {
    return <div className="grid-item">
      {
        landFundData && <>
          {
            <FormBlock type='white'>
              <div className="title bold">{t('screening-page.large-landowners.title', lang)} </div>
              {
                landFundData.largeLandowners && landFundData.largeLandowners.map((item, index) => (
                  <FormBlock type='white'>
                    <FormGroup >
                      <label
                        htmlFor={`landFundData.largeLandowners[${index}].name`}
                        className="required"
                      >{t('screening-page.large-landowners.name', lang)}
                      </label>
                      <Field
                        disabled={selectedDataYear !== currentYear}
                        name={`landFundData.largeLandowners[${index}].name`}
                        className={`${errors[`largeLandowners[${index}].name`] ? 'error' : ''}`}
                        type='text'
                        as="input"
                        onChange={(e: any) => {
                          setLandFundData({
                            ...landFundData,
                            largeLandowners: landFundData.largeLandowners && landFundData.largeLandowners.map((item, i) => i === index ? {
                              ...item,
                              name: e.target.value
                            } : item)
                          });
                          setFieldValue(`landFundData.largeLandowners[${index}].name`, e.target.value);
                        }}
                        value={landFundData?.largeLandowners?.[index].name || ''}
                      />
                    </FormGroup>
                    {['total_land', 'total_arable', 'total_pasture', 'fact_land', 'fact_arable', 'fact_pasture'].map((key, i) => (
                      <FormGroup key={key}>
                        <label
                          htmlFor={`landFundData.largeLandowners[${index}].${key}`}
                          className="required"
                        >{t('screening-page.large-landowners.' + key, lang)}
                        </label>
                        <Field
                          disabled={selectedDataYear !== currentYear}
                          name={`landFundData.largeLandowners[${index}].${key}`}
                          className={`${errors[`largeLandowners[${index}].${key}`] ? 'error' : ''}`}
                          type='number'
                          as="input"
                          min={0}
                          step={'0.0001'}
                          onChange={(e: any) => {
                            const val = e.target.value || '';
                            setLandFundData({
                              ...landFundData,
                              largeLandowners: landFundData.largeLandowners && landFundData.largeLandowners.map((item, i) => i === index ? {
                                ...item,
                                [key]: val
                              } : item)
                            });
                            setFieldValue(`landFundData.largeLandowners[${index}].${key}`, +val);
                          }}
                          value={landFundData?.largeLandowners?.[index][key as keyof ILargeLandowners] || ''}
                        />
                      </FormGroup>
                    ))}
                  </FormBlock>
                ))
              }

              {selectedDataYear === currentYear && isAdding && <FormBlock type='white'>
                {
                  keys.map((key) => (
                    <FormGroup key={key}>
                      <label
                        htmlFor={`newObj.${key}`}
                        className="required"
                      >{t('screening-page.large-landowners.' + key, lang)}
                      </label>
                      <Field
                        disabled={selectedDataYear !== currentYear}
                        name={`newObj.${key}`}
                        className={`${errors[`newObj.${key}`] ? 'error' : ''}`}
                        type={key === 'name' ? 'text' : 'number'}
                        as="input"
                        step={'0.0001'}
                        min={0}
                        onChange={(e: any) => {
                          setNewObj({
                            ...newObj,
                            [key]: e.target.value
                          });
                        }}
                        value={newObj[key] || ''}
                      />
                    </FormGroup>
                  ))
                }
              </FormBlock>}


              {selectedDataYear === currentYear && (
                isAdding ? (
                  <>
                    <FormBlockButton onClick={handleAddObj}>{t('save')}</FormBlockButton>
                    <FormBlockButton style={{ marginBottom: 10 }} onClick={() => setIsAdding(false)}>
                      {t('cancel')}
                    </FormBlockButton>
                  </>
                ) : (
                  <FormBlockButton style={{ marginBottom: 10 }} onClick={() => setIsAdding(true)}>
                    <AddField className="blue" /> {t('form.add_field')}
                  </FormBlockButton>
                )
              )}

            </FormBlock>
          }

          {
            landFundData.landFundItems && <FormBlock type='white'>
              <div className="title bold">{t('screening-page.land-fund-items.title', lang)} </div>
              {
                landFundData.landFundItems && landFundItemKeys.map((key) => (
                  <FormGroup key={key}>
                    <label
                      htmlFor={`landFundData.landFundItems.${key}`}
                      className="required"
                    >{t('screening-page.land-fund-items.' + key, lang)}
                    </label>
                    <Field
                      name={`landFundData.landFundItems.${key}`}
                      className={`${errors[`landFundItems.${key}`] ? 'error' : ''}`}
                      type='number'
                      as="input"
                      min={0}
                      step={'0.0001'}
                      onChange={(e: any) => {
                        const val = e.target.value || '';
                        setLandFundData({
                          ...landFundData,
                          landFundItems: {
                            ...landFundData.landFundItems,
                            [key]: val
                          }
                        } as ILandFund);
                        setFieldValue(`landFundData.landFundItems.${key}`, +val);
                      }}
                      value={key.includes('total') ? landFundData.landFundItems && countTotal(key.split('_')[0] as 'reserve' | 'agricultural', landFundData.landFundItems) : landFundData?.landFundItems?.[key as keyof ILandFundItem] || ''}
                      disabled={key.includes('total') || selectedDataYear !== currentYear}
                    />
                  </FormGroup>
                ))
              }
            </FormBlock>
          }
        </>
      }
    </div>
  }

  useEffect(() => {
    kato && updateKatoAccessList(katoAccessList, kato, navigate, setTree, setRegion, setSnp);
  }, [katoAccessList, kato]);

  useEffect(() => {
    if (kato) {
      setIsKatoRegion(isRegion(+kato))
    }
  }, [kato])

  useEffect(() => {
    const item = localStorage.getItem('user');
    if (item) {
      const snpInfo = JSON.parse(item);
      if (snpInfo && snpInfo.kato_access) {
        setKatoAccessList(snpInfo.kato_access)
      }
    }
  }, [])

  return (
    <div style={{ position: 'relative' }}>
      {
        <>
          <Wrapper ref={wrapperRef}>
            <Formik
              initialValues={{}}
              onSubmit={(values) => {
                handleSubmitForm(values);;
              }}
              innerRef={formikRef}
            >
              {({ setFieldValue }) => (
                <Form>
                  {renderSelects(getLang())}
                  {renderSelects(getLang() !== 'Kz' ? 'Kz' : 'Ru')}
                  {renderFields(getLang(), setFieldValue)}
                  {renderFields(getLang() !== 'Kz' ? 'Kz' : 'Ru', setFieldValue)}
                  <Footer>
                    <div className="buttons">
                      <FooterButton disabled={currentYear !== selectedDataYear} variant="save"><Save /> {t('save', language)}</FooterButton>
                    </div>
                    <div className="buttons">
                      <FooterButton variant="go-up" type='button' onClick={() => handleGoUp(wrapperRef)}><GoUp /> {t('go-up', language)}</FooterButton>
                    </div>
                  </Footer>
                </Form>
              )}
            </Formik>
          </Wrapper>
        </>
      }
      <ToastContainer />
    </div>
  )
}

export default LandFundPage