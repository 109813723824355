import React, { ChangeEvent, Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik, Field } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import Modal from '../modal-component';

import {
  AddField,
  DeleteX,
  EditPen,
  Save,
  GoUp,
  Trash,
} from '../../assets/icons';

import {
  Wrapper,
  FormBlock,
  FormGroup,
  Footer,
  FooterButton
} from './admin-page.styles';

import {
  IGeneralInfo,
  IProject,
  OfficialRoleType,
} from '../../interfaces/snp.interface';

import { getRegionList, getSettlementTree } from '../../requests/kato.request';
import { getGeneralInfo } from '../../requests/snp.request';
import {
  deleteImage,
  uploadGeneralImage,
  changeStatus,
  deleteAll,
  updateFormPartial,

} from '../../requests/supervisor.request';

import { capitalize, checkVal, getLang, handleGoUp, isRegion, keepCapitalized, t } from '../../utils/helpers.utils'
import ImageGrid from '../insfrastructureProjects/image-grid.component';
import { Text } from '../text.component';
import { cityObject,  currentYear,  OBLAST_KATO } from '../../constants/snp.constant';
import { useSharedContext } from '../../contexts/shared.context';

const initialFormData: any = {
};

const errMsg: any = {
  akimNameKz: 'fullName',
  akimNameRu: 'fullName',
  akimPositionKz: 'position',
  akimPositionRu: 'position',
  akimImageId: 'photo',
  akimPhone: 'phoneS'
}

export const getImageUrl = (imageId: number) => {
  return `/api/public/image/load?imageId=${imageId}`;
}

const GeneralInfo = () => {
  const { i18n: { language } } = useTranslation();
  const navigate = useNavigate();
  const { kato } = useParams();
  const { selectedDataYear } = useSharedContext();

  const formikRef = useRef<any>(null);
  const wrapperRef = useRef<any>(null);

  const [region, setRegion] = useState<number>(0);
  const [snp, setSnp] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [selectedRole, setSelectedRole] = useState<OfficialRoleType | null>(null);
  const [selectedImageName, setSelectedImageName] = useState('');
  const [formData, setFormData] = useState<any>(initialFormData);
  const [isFinished, setIsFinished] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [isKatoRegion, setIsKatoRegion] = useState(false);
  const [katoAccessList, setKatoAccessList] = useState<number[]>([]);
  const [tree, setTree] = useState<any[]>([]);

  const handleRegionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setRegion(+e.target.value);
    kato && isRegion(+kato) && navigate(`/admin/${e.target.value}`)
  }

  const handleSnpChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSnp(+e.target.value)
    navigate(`/admin/${e.target.value}`)
  }

  const validateFormBySections = (values: IProject) => {
    setErrors({});
    // SECTION ONE 
    const sectionOneKeys = ['date', 'population', 'populationDynamic', 'avgSalary', 'area'];

    if (sectionOneKeys.some(key => checkVal(values[key]))) {
      for (const key of sectionOneKeys) {
        if (!checkVal(values[key])) {
          setErrors((prev: any) => ({ ...prev, [key]: true }))
          toast.error(t(`errors.${key}`, language));
          return false;
        }
      }
    }

    // SECTION TWO

    const sectionTwoKeys = ['akimNameKz', 'akimNameRu', 'akimPositionKz', 'akimPositionRu', 'akimInPositionFrom', 'akimPhone'];
    if (sectionTwoKeys.some(key => checkVal(values.generalInfo[key]))) {
      for (const key of sectionTwoKeys) {
        if (!checkVal(values.generalInfo[key])) {
          const foundKey = errMsg[key] || key
          setErrors((prev: any) => ({ ...prev, [foundKey]: true }))
          toast.error(t(`errors.${foundKey}`, language));
          return false;
        }
      }
    }

    return true;
  };

  const handleSubmitForm = (values: IProject, status?: 'COMPLETED' | 'IN_PROCESS') => {
    setErrors({});

    const valuesToSubmit: any = {
      generalInfo: {
        ...values.generalInfo,
        akimNameKz: capitalize(values.generalInfo.akimNameKz) || null,
        akimNameRu: capitalize(values.generalInfo.akimNameRu) || null,
        akimPositionKz: keepCapitalized(values.generalInfo.akimPositionKz) || null,
        akimPositionRu: keepCapitalized(values.generalInfo.akimPositionRu) || null,
        akimImageId: formData.akimImageId || values.generalInfo.akimImageId || null,
      },
      childInfoList: values.childInfoList,
      status: values.status
    }

    if (kato && validateFormBySections(valuesToSubmit)) {
      updateFormPartial(kato, valuesToSubmit, selectedDataYear)
        .then(() => {
          toast.success(t('toast.save_success'))
          loadForm();
        })
        .catch(() => toast.error('Ошибка при сохранении'));
    }
  }

  const handleSelectRole = (role: OfficialRoleType) => {
    setSelectedRole(role);
    openPhotoModal()
  }

  const handleImageUpload = async () => {
    if (!selectedImage) {
      return;
    }

    kato && selectedRole && uploadGeneralImage(+kato, selectedRole, selectedImage, selectedDataYear)
      .then(_ => {
        loadForm();
      })

    closePhotoModal();
  };

  const handleDeleteImage = (imageId: number) => {
    deleteImage(imageId).then(_ => loadForm())
  };

  const handleImageSelect = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setSelectedImageName(file.name || '');
      setSelectedImage(file);
    }
  };

  const handleDelete = () => {
    setIsConfirmOpen(true);
  }

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      if (validateFormBySections(formikRef.current.values)) {
        setIsFinished(true);
        changeStatus(snp, 'COMPLETED').then(res => {
          formikRef.current.setValues({ ...formikRef.current.values, status: 'COMPLETED' })
          handleSubmitForm({ ...formikRef.current.values, status: 'COMPLETED' })
        })
      }
    } else {
      setIsFinished(false);
      changeStatus(snp, 'IN_PROCESS').then(res => {
        formikRef.current.setValues({ ...formikRef.current.values, status: 'IN_PROCESS' })
        handleSubmitForm({ ...formikRef.current.values, status: 'IN_PROCESS' })
      })
    }
  }

  const openPhotoModal = () => {
    setIsOpen(true);
  };

  const closePhotoModal = () => {
    setIsOpen(false);
  };

  const confirmDelete = () => {
    deleteAll(snp).then(() => navigate('/admin/snp'))
  }

  const loadForm = useCallback(() => {
    const val = kato ? kato : snp
    val && getGeneralInfo(+val, selectedDataYear).then((res: any) => {
      const data = {
        ...res,
        generalInfo: {
          ...res.generalInfo,
          date: res.generalInfo?.date ? res.generalInfo.date.split('T')[0] : '',
          akimInPositionFrom: res.generalInfo?.akimInPositionFrom ? res.generalInfo.akimInPositionFrom.split('T')[0] : ''
        },
      }

      const status = res.status;
      setIsFinished(status === 'COMPLETED');

      if (formikRef.current) {
        formikRef.current.resetForm();
      }
      setFormData(data as IGeneralInfo);

      setIsLoading(false);
    });
  }, [snp, kato, selectedDataYear])

  useEffect(() => {
    if (katoAccessList?.length && kato) {
      if (!katoAccessList.includes(+kato) && !katoAccessList.includes(0) && !katoAccessList.some(item => kato.toString().startsWith(item.toString().substring(0, 4)))) {
        navigate('/admin/snp')
      }
      const snpItem: { kato: number, ppKato: number } = JSON.parse(localStorage.getItem('snp') as string);
      const val = kato ? kato : snpItem.kato;
      getSettlementTree().then((res: any) => {
        const data = res.data['Актобе'];

        const tree = Object.keys(data).map((item: string) => (
          {
            nameRu: data[item][0].pnameRu,
            nameKz: data[item][0].pnameKz,
            kato: data[item][0].pkato,
            children: katoAccessList.includes(0) ? data[item] : data[item].filter((item: any) => katoAccessList.includes(+item.pkato) || katoAccessList.includes(+item.kato))
          }))

        setTree(tree);
        // setTree([cityObject, ...tree]);

        if (val) {
          if (isRegion(+val)) {
            setRegion(+val);
          } else if (!isRegion(+val)) {
            setRegion(+(val.toString().substring(0, 4) + '00000'));
            setSnp(+val);
          }
        }
      });
    }
  }, [katoAccessList, kato, selectedDataYear]);

  useEffect(() => {
    if (kato) {
      setIsKatoRegion(isRegion(+kato))
    }
  }, [kato])

  useEffect(() => {
    loadForm();
  }, [loadForm, snp, selectedDataYear]);

  useEffect(() => {
    const item = localStorage.getItem('user');
    if (item) {
      const snpInfo = JSON.parse(item);
      if (snpInfo && snpInfo.kato_access) {
        setKatoAccessList(snpInfo.kato_access)
      }
    }
  }, [])

  const renderSelects = (lang: 'Ru' | 'Kz' = 'Ru') => {
    return (
      kato && +kato !== OBLAST_KATO && <div className="grid-item">
        <FormGroup>
          <label htmlFor="region">{t(`form.region.name`, lang)}</label>
          <Field as="select" value={region} onChange={handleRegionChange} disabled={lang.toLowerCase() !== language}>
            {tree.map((item) => <option key={item.kato} value={item.kato}>{item[`name${getLang()}`]}</option>)}
          </Field>
        </FormGroup>

        {!isKatoRegion && <FormGroup>
          <label htmlFor="snp">{t(`form.district.name`, lang)}</label>
          <Field as="select" value={snp} onChange={handleSnpChange} disabled={lang.toLowerCase() !== language}>
            {tree.find(item => +item.kato === +region)?.children.map((item: any) => <option key={item.kato} value={item.kato}>{item[`name${getLang()}`]}</option>)}
          </Field>
        </FormGroup>}
      </div>
    )
  }

  const onlyLetters = (value: string) => value.replace(/[^\p{L}\s]/gu, '');

  const valueGetter = (value: string) => formData.generalInfo[value] || '';

  const renderFields = (lang: 'Ru' | 'Kz' = 'Ru', setFieldValue: any) => {
    return (
      <div className="grid-item">
        <FormGroup>
          <div className="building">
            <label className='required' htmlFor="generalInfo.date">{t(`aktobe.date`, lang)}</label>
            <Field
              disabled={selectedDataYear !== currentYear}
              className={`${errors[`date`] ? 'error' : ''}`}
              value={valueGetter('date')}
              onChange={(e: any) => {
                setFieldValue('generalInfo.date', e.target.value)
                setFormData({ ...formData, generalInfo: { ...formData.generalInfo, date: e.target.value } })
              }}
              name="generalInfo.date"
              as="input"
              type="date" />
          </div>
        </FormGroup>
        <FormGroup>
          <div className="building">
            <label className='required' htmlFor="generalInfo.population">{t(`aktobe.population`, lang)}</label>
            <Field
              disabled={selectedDataYear !== currentYear}
              className={`${errors[`population`] ? 'error' : ''}`}
              value={valueGetter('population')}
              onChange={(e: any) => {
                setFieldValue('generalInfo.population', e.target.value)
                setFormData({ ...formData, generalInfo: { ...formData.generalInfo, population: e.target.value } })
              }}
              name="generalInfo.population"
              as="input"
              type="number" />
          </div>
        </FormGroup>
        <FormGroup>
          <div className="building">
            <label className='required' htmlFor="generalInfo.populationDynamic">{t(`aktobe.population-dynamic`, lang)}</label>
            <Field
              disabled={selectedDataYear !== currentYear}
              className={`${errors[`populationDynamic`] ? 'error' : ''}`}
              value={valueGetter('populationDynamic')}
              onChange={(e: any) => {
                setFieldValue('generalInfo.populationDynamic', e.target.value)
                setFormData({ ...formData, generalInfo: { ...formData.generalInfo, populationDynamic: e.target.value } })
              }}
              name="generalInfo.populationDynamic"
              as="input"
              type="number"
            />
          </div>
        </FormGroup>

        <FormGroup>
          <div className="building">
            <label className='required' htmlFor="generalInfo.avgSalary">{t(`aktobe.avgSalary`, lang)}, ₸</label>
            <Field
              disabled={selectedDataYear !== currentYear}
              className={`${errors[`avgSalary`] ? 'error' : ''}`}
              value={valueGetter('avgSalary')}
              onChange={(e: any) => {
                setFieldValue('generalInfo.avgSalary', e.target.value)
                setFormData({ ...formData, generalInfo: { ...formData.generalInfo, avgSalary: e.target.value } })
              }}
              name="generalInfo.avgSalary"
              as="input"
              type="number" />
          </div>
        </FormGroup>
        <FormGroup>
          <div className="building">
            <label className='required' htmlFor="generalInfo.area">{t(`aktobe.area`, lang)}, га</label>
            <Field
              disabled={selectedDataYear !== currentYear}
              className={`${errors[`area`] ? 'error' : ''}`}
              value={valueGetter('area')}
              onChange={(e: any) => {
                setFieldValue('generalInfo.area', e.target.value)
                setFormData({ ...formData, generalInfo: { ...formData.generalInfo, area: e.target.value } })
              }}
              name="generalInfo.area"
              as="input"
              type="number" />
          </div>
        </FormGroup>

        <FormBlock type='white'>
          <div className="child-grid" style={{ gridTemplateColumns: `repeat(${isRegion(formData.kato) ? '2' : '3'}, 1fr)` }}>
            <Text fontSize='0.75rem'>{t('aktobe.name')}</Text>
            {!isRegion(formData.kato) && <Text fontSize='0.75rem' ta="right">{isRegion(formData.kato) ? t('aktobe.toOblCenter') : t('aktobe.toCenter')}</Text>}
            <Text fontSize='0.75rem' ta={!isRegion(formData.kato) ? 'right' : 'left'}>{t('aktobe.population')}</Text>
            
            {
              formData.childInfoList.map((item: any, index: number) =>
                <React.Fragment key={index}>
                  <Text>{item[`name${lang}`]}</Text>
                  {!isRegion(formData.kato) && <FormGroup>
                    <Field
                      disabled={selectedDataYear !== currentYear}
                      name={`childInfoList.${index}.distance`}
                      value={formData.childInfoList[index].distance ?? ''}
                      onChange={(e: any) => {
                        setFieldValue(`childInfoList.${index}.distance`, e.target.value)
                        setFormData({ ...formData, childInfoList: formData.childInfoList.map((child: any, i: number) => i === index ? { ...child, distance: e.target.value } : child) })
                      }}
                    />
                  </FormGroup>}
                  <FormGroup>
                    <Field
                      disabled={selectedDataYear !== currentYear}
                      name={`childInfoList.${index}.population`}
                      value={formData.childInfoList[index].population ?? ''}
                      onChange={(e: any) => {
                        setFieldValue(`childInfoList.${index}.population`, e.target.value)
                        setFormData({ ...formData, childInfoList: formData.childInfoList.map((child: any, i: number) => i === index ? { ...child, population: e.target.value } : child) })
                      }}
                    />
                  </FormGroup>
                </React.Fragment>
              )
            }
          </div>
        </FormBlock>

        <FormBlock type='gray' >
          <div className="title">{t(`aktobe.akim-title`, lang)}</div>
          <FormGroup>
            <label
              htmlFor={`generalInfo.akimName${lang}`}
              className={'required'}
            >
              {t(`aktobe.akim.fullName`, lang)}
            </label>
            <Field
              disabled={selectedDataYear !== currentYear}
              name={`generalInfo.akimName${lang}`}
              type="text"
              className={`capitalize ${errors[`fullName`] ? 'error' : ''}`}
              value={valueGetter(`akimName${lang}`)}
              onChange={(e: any) => {
                const val = onlyLetters(e.target.value);
                setFieldValue(`generalInfo.akimName${lang}`, capitalize(val))
                setFormData({ ...formData, generalInfo: { ...formData.generalInfo, [`akimName${lang}`]: capitalize(val) } })
              }}
            />
          </FormGroup>
          <FormGroup>
            <label
              htmlFor={`generalInfo.akimPosition${lang}`}
              className={'required'}
            >
              {t(`aktobe.akim.position`, lang)}
            </label>
            <Field
              disabled={selectedDataYear !== currentYear}
              name={`generalInfo.akimPosition${lang}`}
              type="text"
              value={valueGetter(`akimPosition${lang}`)}
              onChange={(e: any) => {
                const val = onlyLetters(e.target.value);
                setFieldValue(`generalInfo.akimPosition${lang}`, keepCapitalized(val))
                setFormData({ ...formData, generalInfo: { ...formData.generalInfo, [`akimPosition${lang}`]: keepCapitalized(val) } })
              }}
              className={`${errors[`position`] ? 'error' : ''}`}
            />
          </FormGroup>
          <div className="row">
            <FormGroup>
              <label
                htmlFor={`generalInfo.akimInPositionFrom`}
                className={'required'}
              >
                {t(`aktobe.akim.inPositionFrom`, lang)}
              </label>
              <Field
                disabled={selectedDataYear !== currentYear}
                name={`generalInfo.akimInPositionFrom`}
                type="date"
                value={valueGetter('akimInPositionFrom')}
                as='input'
                className={`${errors[`inPositionFrom`] ? 'error' : ''}`}
                onChange={(e: any) => {
                  setFieldValue('generalInfo.akimInPositionFrom', e.target.value)
                  setFormData({ ...formData, generalInfo: { ...formData.generalInfo, akimInPositionFrom: e.target.value } })
                }}
              />
            </FormGroup>
            <FormGroup>
              <label
                htmlFor={`generalInfo.akimPhone`}
                className={'required'}
              >
                {t(`aktobe.akim.phoneNumber`, lang)}
              </label>
              <Field
                disabled={selectedDataYear !== currentYear}
                name={`generalInfo.akimPhone`}
                as="input"
                type="number"
                className={`${errors[`phone`] ? 'error' : ''}`}
                value={valueGetter('akimPhone')}
                onChange={(e: any) => {
                  setFieldValue('generalInfo.akimPhone', e.target.value)
                  setFormData({ ...formData, generalInfo: { ...formData.generalInfo, akimPhone: e.target.value } })
                }}
              />
            </FormGroup>
          </div>

          {
            lang.toLowerCase() === language && <FormGroup>
              <div className="photo">
                <label className={'required'} htmlFor={`akim.photoId`}>{t(`aktobe.akim.photoId`, lang)}</label>
                <div className={`image ${errors[`photoId`] ? 'error' : ''} `}>
                  {formData.generalInfo.akimImageId
                    ? <>
                      <img src={getImageUrl(formData.generalInfo.akimImageId)} alt="person" />
                      <div className="buttons">
                        <div className='edit' onClick={() => handleSelectRole('SUPERVISOR')}><EditPen /> {t('form.photo.edit', language)}</div>
                        <div className='delete' onClick={() => handleDeleteImage(+formData.generalInfo.akimImageId)} ><DeleteX /> {t('form.photo.delete', language)}</div>
                      </div>
                    </>
                    : <div className="buttons">
                      <div className='upload' onClick={() => handleSelectRole('SUPERVISOR')}> <AddField /> {t('form.photo.upload', language)}</div>
                    </div>}
                </div>
              </div>
            </FormGroup>
          }
        </FormBlock>
      </div >
    )
  }

  return (
    <div style={{ position: 'relative' }}>
      {
        !isLoading && <>
          <Wrapper ref={wrapperRef}>
            <Formik
              initialValues={formData}
              onSubmit={(values) => {
                handleSubmitForm(values);;
              }}
              innerRef={formikRef}
              enableReinitialize
            >
              {({ setFieldValue }) => (
                <Form>
                  {renderSelects(getLang())}
                  {renderSelects(getLang() !== 'Kz' ? 'Kz' : 'Ru')}
                  {renderFields(getLang(), setFieldValue)}
                  {renderFields(getLang() !== 'Kz' ? 'Kz' : 'Ru', setFieldValue)}
                  <Footer>
                    <div className="buttons">
                      <FooterButton disabled={currentYear !== selectedDataYear} variant="save"><Save /> {t('save', language)}</FooterButton>
                      <div className='checkbox'>

                        <input type="checkbox" name="is-finished" id="is-finished" checked={isFinished} onChange={handleCheckboxChange} />
                        {kato && +kato !== OBLAST_KATO ? <label htmlFor="is-finished">{t(`${kato && isRegion(+kato) ? 'region' : 'so'}-is-finished`, language)}</label>
                          : <label htmlFor="is-finished">Область заполнена полностью</label>}
                      </div>
                    </div>
                    <div className="buttons">
                      <FooterButton variant="delete" type='button' onClick={handleDelete}><Trash /> {t('delete-all', language)}</FooterButton>
                      <FooterButton variant="go-up" type='button' onClick={() => handleGoUp(wrapperRef)}><GoUp /> {t('go-up', language)}</FooterButton>
                    </div>
                  </Footer>
                </Form>
              )}
            </Formik>

            <ImageGrid
              formData={formData}
              lang={language}
              type='general'
              images={formData.imageIds || []}
              loadForm={loadForm}
            />
          </Wrapper>

          <Modal isFileUpload isOpen={isOpen} onClose={closePhotoModal}>
            <label className="input-file">
              <span className="input-file-text">{selectedImageName}</span>
              <input type="file"
                accept="image/*"
                id="imageInput"
                onChange={handleImageSelect} />
              <span className="input-file-btn">{t('choose-file', language)}</span>
            </label>
            <button className="upload-button" onClick={handleImageUpload}>
              {t('form.photo.upload', language)}
            </button>
          </Modal>
          <Modal isFileUpload isOpen={isConfirmOpen} onClose={() => setIsConfirmOpen(false)}>
            <h1>{t('confirm-delete-village', language)}</h1>
            <div className="buttons">
              <FooterButton variant="delete" type='button' onClick={confirmDelete}><Trash width={16} /> {t('form.requirementsObj.YES', language)}</FooterButton>
              <FooterButton variant="cancel" type='button' onClick={() => setIsConfirmOpen(false)}> {t('form.requirementsObj.NO', language)}</FooterButton>
            </div>
          </Modal>
        </>
      }
      <ToastContainer />
    </div>
  )
}

export default GeneralInfo